<template>
  <div>
    <div class="banner">
      <el-carousel arrow="never">
        <el-carousel-item height="650" v-for="item in bannerImg" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contBox1">
      <div class="leftImg">
        <el-carousel height="450px">
          <el-carousel-item v-for="item in rotationChart" :key="item">
            <img :src="item" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="rightText">
        <h3>{{ projectName }}</h3>
        <p>{{ detailIntroduce }}</p>
      </div>
    </div>
    <div class="contBox2">
      <h2>主要功能</h2>
      <div>
        <div class="subTextBox" v-html="mainFunction"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { findItemDetails, findItem } from '../../api/parentPage'

export default {
  data() {
    return {
      bannerImg: [], //banner轮播图
      projectName: '客户管理系统',
      detailIntroduce:
        'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias.',
      rotationChart: [], //轮播图
      functionItems: [],
      mainFunction: null
    }
  },
  created() {
    this._initData()
  },
  methods: {
    _initData() {
      //查询菜单项目详情
      findItemDetails({ id: this.$route.params.id }).then((res) => {
        if (res.status == 200) {
          console.log(res.data)
          this.projectName = res.data.projectName
          this.detailIntroduce = res.data.detailIntroduce
          this.bannerImg = res.data.projectBanner.split(',')
          this.mainFunction = res.data.mainFunction
          //处理轮播图
          this.rotationChart = res.data.projectDetailImg.split(',')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  ::v-deep .el-carousel > .el-carousel__container {
    height: 650px !important;
    .el-carousel__item {
      width: 100%;
      text-align: center;
      > img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }
  }
}

.contBox1 {
  width: 1200px;
  height: 450px;
  margin: auto;
  margin-top: 80px;
  display: flex;
  align-items: center;
  > .leftImg {
    width: 574px !important;
    height: 450px;
    margin-right: 35px;

    border-radius: 15px;
    overflow: hidden;
    .el-carousel {
      > .el-carousel__container {
        > .el-carousel__item {
          > img {
            border-radius: 15px;
            width: 574px;
            height: 450px;
            object-fit: cover;
          }
        }
      }
    }
  }
  > .rightText {
    width: 590px;
    height: 450px;
    text-align: start;
    h3 {
      font-weight: 700;
      font-size: 46px;
      color: #484848;
      margin: 30px 0;
      display: -webkit-box; //对象作为弹性伸缩盒子模型显示
      overflow: hidden; //溢出隐藏
      -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
      -webkit-line-clamp: 2; //设置 块元素包含的文本行数
    }
    > p {
      height: 100px;
      font-weight: 400;
      font-size: 16px;
      color: #9a9a9a;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
}
.contBox2 {
  width: 1200px;
  padding: 110px 0px 100px;
  margin: auto;
  > h2 {
    text-align: start;
    font-weight: 700;
    font-size: 46px;
    color: #484848;
    margin: 12px 0px 60px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}
.subTextBox {
  width: 1200px;
  overflow: hidden;
}
</style>
